import * as _ from "lodash";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const calendarDateFormat = "MM/DD/YYYY";
const calendarDateTimeFormat = "MM/DD/YYYY hh:mm:ss a";
const ISODateTimeFormat = "YYYY-MM-DD HH:mm:ss.SSS";
const dateRegExp = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

export const kendoDateFormat = "{0: MM/dd/yyyy}";
export const kendoDateTimeFormat = "{0: MM/dd/yyyy, h:mm a}";

export const addMonthsToDate = (date: Date, n: number): Date => {
    return new Date(date.getFullYear(), date.getMonth() + n, date.getDate());
};

export const addDaysToDate = (date: Date, n: number): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + n);
};

export const getFirstOfMonth = (date?: Date): Date => {
    const refDate = date ?? new Date();
    return new Date(refDate.getFullYear(), refDate.getMonth(), 1);
};

export const getLastOfMonth = (date?: Date): Date => {
    const refDate = date ?? new Date();
    const firstOfNextMonth = addMonthsToDate(getFirstOfMonth(refDate), 1);
    return addDaysToDate(firstOfNextMonth, -1);
};

export const getISODateTimeStamp = (date?: string): string => {
    return dayjs(date || new Date())
        .tz("America/Chicago")
        .format(ISODateTimeFormat);
};

/** Formats date string as a MM/DD/YYYY */
export const formatDate = (dateStr: string): string =>
    dateStr ? `${dateStr.substr(5, 2)}/${dateStr.substr(8, 2)}/${dateStr.substr(0, 4)}` : "";

/**Formats date string as MM/DD/YYYY H:MM AM/PM */
export const formatDateTime = (dateStr: string): string => {
    // example: 12/04/2020 5:24 PM
    const hr = +dateStr.substr(11, 2);
    const ampm = hr >= 12 ? "PM" : "AM";
    return `${dateStr.substr(5, 2)}/${dateStr.substr(8, 2)}/${dateStr.substr(0, 4)} ${hr % 12 || 12}:${dateStr.substr(14, 2)} ${ampm}`;
};

export const toFormattedDateString = (date: Date | string, retainTime = false, format?: string): string => {
    let outputString = "";
    let outputFormat = calendarDateFormat;
    try {
        switch (true) {
            case !!format:
                outputFormat = format as string;
                break;
            case retainTime:
                outputFormat = calendarDateTimeFormat;
                break;
            default:
                outputFormat = calendarDateFormat;
        }
        if (typeof date === "string") {
            outputString = dayjs(date).format(outputFormat);
        } else if (typeof date === "object") {
            outputString = dayjs(date.toISOString()).format(outputFormat);
        }
    } catch (error) {
        console.error(`error converting date ${date} to formatted date string`);
    }
    return outputString;
};

export function fromIsoDateString(date: string): Date {
    const [year, month, day] = date.split("-");
    return new Date(+year, +month - 1, +day);
}

/** Formats a date object as ISO format YYYY-MM-DD */
export function toIsoDateString(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

/**
 * Returns an array of objects by parsing the selected fields as Date objects
 * @param {Object[]} data - An array of objects
 * @param {string[]} fields - An array of field names to be parsed as Date objects
 * @param {string} behavior - remove 'Z' from datetime string, setting timezone to local;
 * (behavior is often used when you want only the date, and with non-datetimeoffset data like most dates in MedDiag)
 */
export const parseDates = <T>(data: T[], fields: (keyof T)[], behavior?: "TRIM Z"): T[] => {
    const parsed = data.map((row) => {
        const mutableRow = { ...row };
        fields.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = mutableRow[field] as any;
            if (value) {
                const preparedValue = behavior === "TRIM Z" ? value.split("Z")[0] : value;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mutableRow[field] = new Date(preparedValue) as any;
            }
        });
        return mutableRow;
    });
    return parsed as T[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function processObjectWithDates(item: any, retainTime?: boolean, format?: string) {
    for (const key of Object.keys(item)) {
        if (dateRegExp.test(item[key])) {
            const dateString: string = item[key];
            let tempDate: Date | string = processDateString(dateString, retainTime);
            if (format) {
                tempDate = dayjs(tempDate).format(format);
            }
            item[key] = tempDate;
        }
    }
    return item;
}

function processDateString(dateString: string, retainTime?: boolean): Date {
    if (retainTime) {
        const tempDateTimeString: string = dateString.replace(/Z/, ""); // remove erroneous Z timezone, thereby setting timezone to local time
        return new Date(tempDateTimeString);
    } else {
        const tempDateOnlyString: string = dateString.replace(/T.*/, "");
        return new Date(tempDateOnlyString + "T00:00:00");
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function processDates(data: any, retainTime?: boolean, format?: string): any {
    try {
        if (_.isArray(data)) {
            const tempData = data.map((item) => {
                return { ...item, CreatedDate: new Date(item.CreatedDate) };
            });
            return tempData.map((item) => processObjectWithDates(item, retainTime, format));
        } else if (_.isDate(data)) {
            // format single date
            if (format) {
                return dayjs(data).format(format);
            } else {
                console.error("Error: must provide date format");
            }
        } else if (!_.isArray(data) && _.isObject(data)) {
            return processObjectWithDates(data, retainTime, format);
        }
    } catch (err) {
        console.error(err);
    }
}

/** returns true if data is ISO format or MM/DD/YYYY (at the minimum) */
export const isValidDate = (date: string): boolean => {
    return dayjs(date).isValid();
};
